a {
	/*color: #0366d6;*/
	text-decoration: none;
	font-weight: bold;
}

.my-pic {
	width: 300px;
	height: 300px;
	object-fit: cover;
}

.cardHost {
	margin-top: 40px;
	position: relative;
}

.card-portrait {
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 10px;
	background: var(--bs-secondary);
	display: flex;
	width: 330px;
	height: 440px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.hover-portrait-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 330px);
	grid-auto-rows: 440px;
	grid-gap: 50px;
	justify-content: space-around;
}

.card-landscape {
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 10px;
	background: var(--bs-secondary);
	display: flex;
	width: 350px;
	height: 262px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.hover-landscape-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 350px);
	grid-auto-rows: 262px;
	grid-gap: 50px;
	justify-content: space-around;
}
